import React, { useState, useEffect, useCallback, useRef } from "react";

import { HTTP } from '../../http/http-common';
import { ArrowUpORDown, BackButton, BoxText, CheckBox, Circle, CloseButton, ContainerCellHarmonicBody, ContainerCellHarmonicHead, ContainerCellTextHarmonicBody, ContainerCellTextHarmonicHead, ContainerCodePen, ContainerColumnHarmonicBody, ContainerColumnHarmonicHead, ContainerFilters, ContainerFiltersBar, ContainerGeneral, ContainerGridOptions, ContainerHarmonics, ContainerHeaderCodePen, ContainerIconOption, ContainerOption, ContainerOptionInline, ContainerRowHarmonicBody, ContainerRowHarmonicHead, ContainerSearchSymbolInput, ContainerTableHarmonic, ContainerTableHarmonicBody, ContainerTableHarmonicHead, ContainerTitleOption, ContainerTitleOptionsV2, FilterButton, FilterButton2, GridOption, IconOption, ImgSearchSymbol, MainContainerCodePen, ModalOptions, SearchSymbolInput, SubActionsContainer, SubModalContainer, TitleGridOption, TitleOptions, TitleOptionsV2, TitleSetup } from "./NotificationsPageElement";
import { checkIfIncludedSymbol, convertTimestampToDate, futuresCommodities, futuresIndices, majorCryptoPairs, majorPairs, minorPairs, pairs, useOutsideAlerter } from "../../utils/utils";

import InputSearchIcon from '../../../assets/images/InputSearchIcon.png'
import IconTelegramPlane from '../../../assets/images/IconTelegramPlane.png'

import ArrowDown from '../../../assets/images/ArrowDown.png'
import ArrowUp from '../../../assets/images/ArrowUp.png'
import PopUp from "../../hooks/PopUp";
import { TextPoppins } from "../../../AppElement";
import { Loader } from "../../hooks/Loader";
import TradingViewWidget from "../LiveChart/TradingViewWidget";

let changedCheckboxes = {};

function setRow(data, props) {
  console.log("SET ROW", data)
  if (props.setData && props.setCurrentSymbol && props.setInterval && props.setTakeProfits && props.setStopLoss && props.setEntryPrice){
    props.setData(data)
    props.setCurrentSymbol(data.symbol)
    props.setInterval(data.displaytimeframe)
    props.setTakeProfits({"profit1": data.profit1, "profit2": data.profit2, "time" : data.atime})
    props.setStopLoss(data.stoploss)
    props.setEntryPrice(data.entry)
  }
}

function HarmonicTable(props) {

  return (
    <ContainerTableHarmonic style={{overflowY: props.rows.length <= 1 ? "hidden" : "scroll"}}>
      <ContainerTableHarmonicHead>
        <ContainerRowHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
                Symbol
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
                Pattern
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
                Status
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
                TimeFrame
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
               Entry 1
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
               Entry 2
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
                Profit 1
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
                Stoploss
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead>
              <ContainerCellTextHarmonicHead>
                Reward/Risk
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
          <ContainerColumnHarmonicHead>
            <ContainerCellHarmonicHead style={{width: '130px'}}>
              <ContainerCellTextHarmonicHead>
                Date
              </ContainerCellTextHarmonicHead>
            </ContainerCellHarmonicHead>
          </ContainerColumnHarmonicHead>
        </ContainerRowHarmonicHead>
      </ContainerTableHarmonicHead>
      <ContainerTableHarmonicBody>
        {
          props.rows.map((row) => (
            <>
            { checkIfIncludedSymbol(row.displaysymbol) ?
              <ContainerRowHarmonicBody onClick={()=>setRow(row, props)} >
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody style={{display: 'flex'}}>
                    {parseFloat(row.profit1) < parseFloat(row.entry) && parseFloat(row.stoploss) > parseFloat(row.entry) ?
                      <ArrowUpORDown src={ArrowDown} />
                      :
                      <ArrowUpORDown src={ArrowUp} />
                    }
                    <ContainerCellTextHarmonicBody>
                      {row.displaysymbol}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                      {row.patternname}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                    {row.status === 'failed'?
                        <Circle style={{background: '#DF0707'}} />
                      :
                        <>
                        {row.status === 'successful'?
                          <Circle style={{background: '#1DA534'}}/>
                        :
                          <>
                          {row.status === 'complete'?
                            <Circle style={{background: '#0099ff'}}/>
                          :
                            <>
                            {row.status === 'incomplete'?
                              <Circle style={{background: '#ffff00'}}/>
                            :
                              null
                            }
                            </>
                          }
                          </>
                        }
                        </>
                      }
                      {row.status}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                      {row.displaytimeframe}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                      {parseFloat(row.entry).toFixed(10)}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                      {parseFloat(row.entry).toFixed(10)}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                      {parseFloat(row.profit1).toFixed(10)}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                      {parseFloat(row.stoploss).toFixed(10)}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                        <>
                          {parseFloat(row.profit1) < parseFloat(row.entry) && parseFloat(row.stoploss) > parseFloat(row.entry) ?
                            <>
                              1:{Math.round((parseFloat(row.profit1) -  parseFloat(row.entry)) / (parseFloat(row.entry) - parseFloat(row.stoploss)))}
                            </>
                            :
                            <>
                              1:{Math.round((parseFloat(row.entry) - parseFloat(row.profit1)) / (parseFloat(row.stoploss) - parseFloat(row.entry)))}
                            </>
                          }
                        </>
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
                <ContainerColumnHarmonicBody>
                  <ContainerCellHarmonicBody>
                    <ContainerCellTextHarmonicBody>
                    {convertTimestampToDate(row.date)}
                    </ContainerCellTextHarmonicBody>
                  </ContainerCellHarmonicBody>
                </ContainerColumnHarmonicBody>
              </ContainerRowHarmonicBody>
              :
              null
            }
          </>
          ))
        }
      </ContainerTableHarmonicBody>
    </ContainerTableHarmonic>
  );
}

function ContainerOptions(props){
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    changedCheckboxes[name] = {
      checked: checked,
      groupTitle: props.title,
      name: name,
    };
    
    if (props.setCurrentPage) {
      props.setCurrentPage(1);
    }
    
    if (props.filters.includes(name)) {
      props.setFilters(prevState => {
        const newState = prevState.filter(item => item !== name);
        console.log("New filter state:", newState);
        return newState;
      });
    } else {
      if (checked) {
        props.setFilters(prevState => {
          const newState = [...prevState, name];
          console.log("New filter state:", newState);
          return newState;
        });
      }
    }
  }

  return (
    <>
      <TitleOptions>
        {props.title}
      </TitleOptions>
      <ContainerGridOptions>
        {
          props.items.map((item) => (
            <GridOption>
              <CheckBox type="checkbox" onChange={handleCheckboxChange} name={item} checked={props.filters.includes(item)}/>
              <TitleGridOption>
                {item}
              </TitleGridOption>
            </GridOption>
          ))
        }
      </ContainerGridOptions>
    </>
  );
}

// function ContainerOptionsV2(props){

//   return (
//     <ContainerOptionInline>
//       <ContainerTitleOption>
//         <ContainerIconOption>
//           <IconOption src={props.icon} />
//         </ContainerIconOption>
//         <TitleOptionsV2 style={{border: 'none', justifyContent: 'center'}}>
//         {props.title}
//         </TitleOptionsV2>
//       </ContainerTitleOption>
//       <ContainerOption onClick={()=>window.open("https://t.me/Avantage00001_bot", "_blank")}>
//         <TitleSetup>
//           Setup Now
//         </TitleSetup>
//         {/* <Switch /> */}
//       </ContainerOption>
//     </ContainerOptionInline>
//   );
// }

// function Switch(){
//   return (
//     <LabelSwitch>
//       <ActiveButton type="checkbox" />
//       <SpanSlider/>
//     </LabelSwitch>
//   )
// }


export function NotificationsPage(props) {

  const [filtersStatus, setFiltersStatus] = useState([]);
  const [filtersTimeFrame, setFiltersTimeFrame] = useState([]);
  const [filtersPattern, setFiltersPattern] = useState([]);
  const [filtersSymbol, setFiltersSymbol] = useState([]);

  const [data, setData] = useState(null);
  const [currentSymbol, setCurrentSymbol] = useState();
  const [interval, setInterval] = useState("");
  const [takeProfits, setTakeProfits] = useState([]);
  const [entryPrice, setEntryPrice] = useState(0);
  const [stopLoss, setStopLoss] = useState(0);

  const [timeFrame, setTimeFrame] = useState([]);
  const [status, setStatus] = useState([]);
  const [pattern, setPattern] = useState([]);
  const [symbol, setSymbol] = useState([]);

  const [openSettings, setOpenSettings] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openPairs, setOpenPairs] = useState(false);

  const [showPopUp, setShowPopUp] = useState(false);

  const [loader, setLoader] = useState(false);
  const [loaderTable, setLoaderTable] = useState(false);

  const [filteredRows, setFilteredRows] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const useRefopenSettings = useRef(null);
  useOutsideAlerter(useRefopenSettings, setOpenSettings);

  const useRefopenPairs = useRef(null);
  useOutsideAlerter(useRefopenPairs, setOpenPairs);

  const useRefopenNotifications = useRef(null);
  useOutsideAlerter(useRefopenNotifications, setOpenNotifications);

  const [settings, setSettings] = useState(null);

  const [copied, setCopied] = useState(false);

  const fetchGetPairs = useCallback(() => {
    setLoader(true);
    HTTP.request('get/pairs/telegram', {withCredentials: true})
      .then((res) => {
        if (res.data.result) {
          setFiltersSymbol(res.data.response)
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  const fetchGetSettings = useCallback(() => {
    HTTP.request('get/settings', { withCredentials: true })
      .then((res) => {
        if (res.data.result) {
  
          if (res.data.response) {
            setSettings(res.data.response);

            if (res.data.response.pattern_status) {
              setFiltersStatus(res.data.response.pattern_status);
            }

            if (res.data.response.pattern) {
              setFiltersPattern(res.data.response.pattern);
            }

            if (res.data.response.time_frame) {
              setFiltersTimeFrame(res.data.response.time_frame);
            }

          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
      });
  }, []);

  const updatePairs = () => {
    setLoader(true);
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const raw = {
      "pairs": filtersSymbol
    };

    var config = {
      headers: headers,
      data: raw,
      method: "POST",
      withCredentials: true
    };

    HTTP.request('update/pairs/telegram', config)
    .then((res) => {
        console.log(res);
    })
    .catch((err) => {
        console.log(err);
    })
    .finally(() => {
      setLoader(false);
    });

    setOpenPairs(false)
  };

  const fetchTotalPages = useCallback(() => {
    HTTP.request('all/pagination', {
      method: 'POST',
      data: {
        pattern_status: filtersStatus,
        time_frame: filtersTimeFrame,
        pattern: filtersPattern,
        symbol: filtersSymbol,
        search: document.getElementById("idSearchSymbol")?.value || ""
      }
    })
      .then((res) => {
        if (res.data.result) {
          setTotalPages(res.data.paginated);
        }
      })
      .catch((err) => {
        console.log("Pagination API Error:", err);
      });
  }, [filtersStatus, filtersTimeFrame, filtersPattern, filtersSymbol]);

  const fetchRoutesTimes = useCallback(() => {
    setLoaderTable(true);
    HTTP.request('scan/pattern/allpoints', {
      method: 'POST',
      data: {
        pattern_status: filtersStatus,
        time_frame: filtersTimeFrame,
        pattern: filtersPattern,
        symbol: filtersSymbol,
        search: document.getElementById("idSearchSymbol")?.value || "",
        page: currentPage
      }
    })
      .then((res) => {
        if (res.data.result) {
          setFilteredRows(res.data.rows);

          setStatus(['complete', 'failed', 'incomplete', 'successful']);
          setTimeFrame(['D', 'H1', 'H4', 'M15', 'M30', 'M5']);
          setPattern([
            'alternate bat',
            'bat',
            'butterfly',
            'crab',
            'cypher',
            'deep crab',
            'double bottom',
            'double top',
            'gartley',
            'head and shoulders',
            'shark',
            'triangle',
            'triple bottom',
            'triple top',
            'wedge'
          ]);
          setSymbol(pairs);
        }
      })
      .catch((err) => {
        console.log("API Error:", err);
      })
      .finally(() => {
        setLoaderTable(false);
      });
  }, [filtersStatus, filtersTimeFrame, filtersPattern, filtersSymbol, currentPage]);

  const searchSymbolInput = useCallback((event) => {
    setCurrentPage(1);
    const timer = setTimeout(() => {
      fetchRoutesTimes();
      fetchTotalPages();
    }, 300);
    return () => clearTimeout(timer);
  }, [fetchRoutesTimes, fetchTotalPages]);

  useEffect(() => {
    props.fetchHealth();
  }, [props]);

  useEffect(() => {
    fetchGetPairs();
  }, [fetchGetPairs]);

  useEffect(() => {
    fetchGetSettings();
  }, [fetchGetSettings]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchTotalPages();
      await fetchRoutesTimes();
    };
    fetchData();
  }, [currentPage, filtersStatus, filtersTimeFrame, filtersPattern, filtersSymbol, fetchRoutesTimes, fetchTotalPages]);

  const clearEverything = () =>{
    setFiltersSymbol([])
    setFiltersStatus([]);
    setFiltersTimeFrame([]);
    setFiltersPattern([]);
    setShowPopUp(false);
  }

  const updateSettings = () => {
    setLoader(true);
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const raw = {
      "checkboxes": changedCheckboxes
    };

    var config = {
      headers: headers,
      data: raw,
      method: "POST",
      withCredentials: true
    };

    HTTP.request('update/settings', config)
      .then((res) => {
        console.log(res);
        changedCheckboxes = {}; // Reset the changed checkboxes
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });

    setOpenSettings(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(settings.telegram_pass)
      .then(() => setCopied(true))
      .catch(err => console.error('Error copying text: ', err));
  };

  return (
    <ContainerGeneral>
        <Loader loading={loader}/>
        {!data ?
        <ContainerHarmonics>
          <ContainerFilters>
            <ContainerSearchSymbolInput>
              <ImgSearchSymbol src={InputSearchIcon}/>
              <SearchSymbolInput onKeyUp={searchSymbolInput} placeholder="Search symbol" id="idSearchSymbol" />
            </ContainerSearchSymbolInput>
            <ContainerFiltersBar>
              {openSettings ?
                <FilterButton2>
                  Settings
                </FilterButton2>
                :
                <FilterButton onClick={() => setOpenSettings(true)}>
                  Settings
                </FilterButton>
              }
              {openSettings ? 
                <ModalOptions ref={useRefopenSettings}>
                  <SubModalContainer>
                    <ContainerOptions 
                      title='Patter Status' 
                      items={status} 
                      setFilters={setFiltersStatus} 
                      filters={filtersStatus}
                      setCurrentPage={setCurrentPage}
                    />
                    <ContainerOptions 
                      title='Time Frame' 
                      items={timeFrame} 
                      setFilters={setFiltersTimeFrame} 
                      filters={filtersTimeFrame}
                      setCurrentPage={setCurrentPage}
                    />
                    <ContainerOptions 
                      title='Pattern' 
                      items={pattern} 
                      setFilters={setFiltersPattern} 
                      filters={filtersPattern}
                      setCurrentPage={setCurrentPage}
                    />
                  </SubModalContainer>
                  <SubActionsContainer>
                    <CloseButton onClick={() => updateSettings()}>OK</CloseButton>
                  </SubActionsContainer>
                </ModalOptions>
                :
                null
              }
              {openPairs ?
                <FilterButton2>
                  Pairs
                </FilterButton2>
                :
                <FilterButton onClick={() => setOpenPairs(true)}>
                  Pairs
                </FilterButton>
              }
              {openPairs ?
                <ModalOptions ref={useRefopenPairs}>
                  <SubModalContainer>
                    <ContainerOptions title='Major Pairs' items={symbol.filter(element => majorPairs.includes(element))} setFilters={setFiltersSymbol} filters={filtersSymbol} />
                    <ContainerOptions title='Minor Pairs' items={symbol.filter(element => minorPairs.includes(element))} setFilters={setFiltersSymbol} filters={filtersSymbol} />
                    <ContainerOptions title='Futures Indices' items={symbol.filter(element => futuresIndices.includes(element))} setFilters={setFiltersSymbol} filters={filtersSymbol} />
                    <ContainerOptions title='Futures Commodities' items={symbol.filter(element => futuresCommodities.includes(element))} setFilters={setFiltersSymbol} filters={filtersSymbol} />
                    <ContainerOptions title='Cryptocurrency Pairs' items={symbol.filter(element => {
                        if (element.includes("/")) {
                          if (majorCryptoPairs.includes(element.split('/')[0])){
                            return element
                          }
                        }
                        return null
                      })} setFilters={setFiltersSymbol} filters={filtersSymbol} />
                  </SubModalContainer>
                  <SubActionsContainer>
                    <CloseButton onClick={() => updatePairs()}>OK</CloseButton>
                  </SubActionsContainer>
                </ModalOptions>
                :
                null
              }
              {openNotifications ?
                <FilterButton2>
                  Notifications
                </FilterButton2>
                :
                <FilterButton onClick={() => setOpenNotifications(true)}>
                  Notifications
                </FilterButton>
              }
              {openNotifications ? 
                <ModalOptions ref={useRefopenNotifications} style={{width: '396px'}}>
                  <SubModalContainer>
                    <ContainerOptionInline>
                      <ContainerTitleOption>
                        <ContainerIconOption>
                          <IconOption src={IconTelegramPlane} />
                        </ContainerIconOption>
                        <ContainerTitleOptionsV2>
                          <TitleOptionsV2 style={{border: 'none', justifyContent: 'left'}}>
                            Telegram (send the command and use your monitor telegram password)
                          </TitleOptionsV2>
                          <TitleOptionsV2 style={{border: 'none', justifyContent: 'left'}}>
                            Command: <BoxText>/monitorNotifications</BoxText>
                          </TitleOptionsV2>
                          {settings && settings.telegram_pass ?
                            <TitleOptionsV2 style={{border: 'none', justifyContent: 'left'}}>
                              Password: <BoxText>••••••••</BoxText>
                              <CloseButton onClick={handleCopy} style={{marginTop: '0px'}}>
                                {copied ? 'Copied!' : 'Copy'}
                              </CloseButton>
                            </TitleOptionsV2>
                            :
                            null
                          }
                        </ContainerTitleOptionsV2>
                      </ContainerTitleOption>
                      <ContainerOption onClick={()=>window.open("https://t.me/Avantage00001_bot", "_blank")}>
                        <TitleSetup>
                          Setup Now
                        </TitleSetup>
                      </ContainerOption>
                    </ContainerOptionInline>
                  </SubModalContainer>
                  <SubActionsContainer>
                    <CloseButton onClick={() => setOpenNotifications(false)}>OK</CloseButton>
                  </SubActionsContainer>
                </ModalOptions>
                :
                null
              }
              <FilterButton onClick={() => setShowPopUp(true)}>
                Clear
              </FilterButton>
            </ContainerFiltersBar>
          </ContainerFilters>
          <div style={{position: 'relative'}}>
            <Loader loading={loaderTable} style={{position: 'absolute'}}/>
            <HarmonicTable rows={filteredRows} setData={setData} setCurrentSymbol={setCurrentSymbol} setInterval={setInterval} setTakeProfits={setTakeProfits} setStopLoss={setStopLoss} setEntryPrice={setEntryPrice}/>
          </div>
          <Pagination 
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </ContainerHarmonics>
        :
        null
        }
        {data ?
          <ContainerHarmonics>
            <BackButton onClick={()=>setData(null)}>
              Back
            </BackButton>
            <ContainerHeaderCodePen>
            <HarmonicTable rows={[data]}/>
            </ContainerHeaderCodePen>
            <MainContainerCodePen>
              <ContainerCodePen>
                <TradingViewWidget data={data} symbol={currentSymbol} interval={interval} takeProfits={takeProfits} stopLoss={stopLoss} entryPrice={entryPrice}/>
              </ContainerCodePen>
            </MainContainerCodePen>
          </ContainerHarmonics>
          :
          null
        }
        <PopUp show={showPopUp} onClose={() => setShowPopUp(false)}  onlyConfirm={false} onConfirm={()=>clearEverything()}>
          <TextPoppins style={{letterSpacing: '0'}}>
          Are you sure to clear everything?
          </TextPoppins>
        </PopUp>
    </ContainerGeneral>
  );
}

function Pagination({ currentPage, totalPages, onPageChange }) {
  const getPageNumbers = () => {
    const pages = [];
    if (totalPages <= 7) {
      // Show all pages if total is 7 or less
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Always show first page
      pages.push(1);
      
      if (currentPage <= 3) {
        // Near the start
        pages.push(2, 3, 4, '...', totalPages);
      } else if (currentPage >= totalPages - 2) {
        // Near the end
        pages.push('...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        // Middle
        pages.push('...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
      }
    }
    return pages;
  };

  const buttonStyle = {
    padding: '8px 12px',
    margin: '0 4px',
    backgroundColor: '#1E1E1E',
    color: '#FFFFFF',
    border: '1px solid #333333',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    fontSize: '14px',
    minWidth: '35px',
  };

  const activeButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#0099ff',
    borderColor: '#0099ff',
  };

  const disabledButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#1E1E1E',
    borderColor: '#333333',
    cursor: 'not-allowed',
    opacity: 0.5,
  };

  const paginationContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    margin: '20px 0',
    padding: '10px',
  };

  return (
    <div style={paginationContainerStyle}>
      <button 
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        style={currentPage === 1 ? disabledButtonStyle : buttonStyle}
      >
        &lt;
      </button>
      
      {getPageNumbers().map((page, index) => (
        <button
          key={index}
          onClick={() => typeof page === 'number' ? onPageChange(page) : null}
          style={
            typeof page === 'number' 
              ? (currentPage === page ? activeButtonStyle : buttonStyle)
              : {...buttonStyle, cursor: 'default'}
          }
        >
          {page}
        </button>
      ))}
      
      <button 
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        style={currentPage === totalPages ? disabledButtonStyle : buttonStyle}
      >
        &gt;
      </button>
    </div>
  );
}